import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Image,
  Text,
  Badge,
  Flex,
  WrapItem,
  Wrap,
  HStack,
} from "@chakra-ui/react";
import { Markup } from "interweave";
import { Link } from "gatsby";

const formatAuthors = (authors) => {
  let authorString = "";
  if (authors.length > 8) {
    authorString = authors
      .slice(0, 6)
      .map((author) => `${author.firstName} ${author.lastName}`)
      .join(", ");
    authorString = `${authorString}, et al., `;
  } else if (authors.length > 1) {
    authorString = authors
      .slice(0, authors.length - 1)
      .map((author) => `${author.firstName} ${author.lastName}`)
      .join(", ");
    authorString = `${authorString} and `;
  }
  const lastAuthor = authors[authors.length - 1];
  return `${authorString} ${lastAuthor.firstName} ${lastAuthor.lastName}`;
};

const previousMonth = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return date.toLocaleString("default", { month: "short" });
};

const Summary = (props) => {
  const {
    authors,
    categories,
    doi,
    figureTitle,
    publishedDate,
    title,
    abstract,
    figure,
    isList,
    isCorrection,
    citations,
    pmcViews,
  } = props;

  const authorString = formatAuthors(authors);

  const articleId =
    doi && doi.includes("/") && doi.split("/").pop().replace(/\./g, "-");

  if (!articleId) return null;

  const fallbackText = isCorrection ? "Correction" : "Figure loading...";

  if (isList) {
    return (
      <Link to={`/journals/biology/${articleId.toLowerCase()}`}>
        <Flex
          display="col"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="md"
          align="top"
          _hover={{ border: "1px solid" }}
          marginBottom={2}
          backgroundColor="white"
        >
          <HStack>
            <Image
              src={figure}
              alt={figureTitle}
              height="100%"
              overflow="hidden"
              fit="cover"
              width={200}
              top="0"
              fallbackSrc={`https://via.placeholder.com/400/FFFFFF?text=${fallbackText}`}
            />

            <Box p="6" align="left">
              {publishedDate && (
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  letterSpacing="wide"
                  fontSize="xs"
                  ml="2"
                  alignItems="center"
                >
                  PUBLISHED: {new Date(publishedDate).toLocaleDateString()}
                </Box>
              )}
              {categories && (
                <Wrap>
                  {categories.map((category, index) => (
                    <WrapItem key={`${doi}-${index}`}>
                      <Badge
                        borderRadius="full"
                        px="2"
                        colorScheme={
                          category.toLowerCase() === "retracted" ||
                          category.toLowerCase() === "retraction"
                            ? "red"
                            : "teal"
                        }
                      >
                        {category}
                      </Badge>
                    </WrapItem>
                  ))}
                </Wrap>
              )}

              <Box mt="1" fontWeight="bold" lineHeight="tight">
                <Markup content={title} />
              </Box>
              <Box>
                <Text>{authorString}</Text>
                {abstract && <Text isTruncated>{abstract}</Text>}
              </Box>
            </Box>
          </HStack>
        </Flex>
      </Link>
    );
  }

  return (
    <Link to={`/journals/biology/${articleId.toLowerCase()}`}>
      <Flex
        display="col"
        width="sm"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        boxShadow="md"
        height="md"
        align="top"
        _hover={{ border: "1px solid" }}
        backgroundColor="white"
      >
        <Image
          src={figure}
          alt={figureTitle}
          height={200}
          overflow="hidden"
          fit="cover"
          width="100%"
          top="0"
         // fallbackSrc={`https://via.placeholder.com/400/FFFFFF?text=${fallbackText}`}
        />

        {publishedDate && (
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            ml="2"
            alignItems="center"
          >
            PUBLISHED: {new Date(publishedDate).toLocaleDateString()}
          </Box>
        )}
        {citations && citations.length > 0 && (
          <Badge borderRadius="full" px="2" colorScheme="purple">
            # Citations: {citations.length}
          </Badge>
        )}
        {pmcViews && pmcViews > 0 && (
          <Badge borderRadius="full" px="2" colorScheme="purple">
            # Views at PMC for {previousMonth()}: {pmcViews}
          </Badge>
        )}
        <Box p="6" align="left">
          {categories && (
            <Wrap spacingX="0.3rem">
              {categories.map((category, index) => (
                <WrapItem key={`${doi}-${index}`}>
                  <Badge
                    borderRadius="full"
                    px="2"
                    colorScheme={
                      category.toLowerCase() === "retracted" ||
                      category.toLowerCase() === "retraction"
                        ? "red"
                        : "teal"
                    }
                  >
                    {category}
                  </Badge>
                </WrapItem>
              ))}
            </Wrap>
          )}

          <Box mt="1" fontWeight="bold" lineHeight="tight">
            <Markup content={title} />
          </Box>
          <Box>
            <Text fontSize="sm">{authorString}</Text>
            {abstract && <Text isTruncated>{abstract}</Text>}
          </Box>
        </Box>
      </Flex>
    </Link>
  );
};

Summary.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  categories: PropTypes.arrayOf(PropTypes.string),
  doi: PropTypes.string,
  figure: PropTypes.string,
  figureTitle: PropTypes.string,
  pdfUrl: PropTypes.string,
  publishedDate: PropTypes.string,
  reviewedBy: PropTypes.string,
  title: PropTypes.string,
};

Summary.defaultProps = {
  authors: [],
  categories: [],
  doi: null,
  figure: null,
  figureTitle: null,
  pdfUrl: null,
  publishedDate: null,
  reviewedBy: null,
  title: null,
};

export default Summary;
